import CardCategoryBox1 from "components/CardCategoryBox1/CardCategoryBox1";
import CardOrganizerBox from "components/CardOrganizerBox/CardOrganizerBox";
import Heading from "components/Heading/Heading";
import { TaxonomyType, OrganizerDisplayType } from "data/types";
import React from "react";

export interface SectionGridOrganizerProps {
  organizers?: OrganizerDisplayType[];
  headingCenter?: boolean;
  className?: string;
  gridClassName?: string;
}


const SectionGridOrganizerBox: React.FC<SectionGridOrganizerProps> = ({
  organizers = [],
  headingCenter = false,
  className = "",
  gridClassName = "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4",
}) => {
  return (
    <div className={`nc-SectionGridCategoryBox relative ${className}`}>
      <Heading
        desc="See who is managing events on Event Go"
        isCenter={headingCenter}
      >
        View Our Organizers
      </Heading>
      <div className={`grid ${gridClassName} gap-5 sm:gap-6 md:gap-8`}>
        {organizers.map((item, i) => (
          <CardOrganizerBox key={i} organizer={item}/>
        ))}
      </div>
    </div>
  );
};

export default SectionGridOrganizerBox;
