import { PostDataType } from "data/types";
import { useState, useEffect } from "react";
import { auth, fs } from "firebaseSetup"
import { onSnapshot, addDoc, doc, collection, getDoc, getDocs, setDoc, Timestamp } from "firebase/firestore"
import { signInAnonymously, signInWithEmailAndPassword } from "firebase/auth"

const API_ROOT_URL = "https://us-central1-blink-574af.cloudfunctions.net/"

export async function eventGoFreeItemsCheckout(
    eventId:string, 
    couponCode:string,
    firstName: string,
    lastName: string,
    email: string,
    uniqueId:string){
        const user_uid = auth.currentUser?.uid || ""
        if (user_uid != ""){
            fetch(`${API_ROOT_URL}v1EventsAPIIssueFreeTickets?user_id=${user_uid}&event_id=${eventId}&unique_id=${uniqueId}&first_name=${firstName}&last_name=${lastName}&email=${email}`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
                }).then(response => response.json())
                .then((data) => {
                    let dataObj = {
                        status: 'failure',
                        success: false,
                        orderId: '',
                    }
                    dataObj = data
                    if (dataObj.success && dataObj.orderId != ""){
                        window.location.href = "/order-confirmation/" + dataObj.orderId
                    }
                    else {
                        window.location.href = "/e/"+eventId
                    }
                })
        }
        
}

export async function eventGoFormSubmission(
    eventId:string, 
    email: string, 
    firstName:string, 
    lastName:string, 
    formContent: any[]){
    const docRef = await addDoc(collection(fs, "events", eventId, "formSubmissions"), {
        forms: formContent,
        email: email,
        firstName: firstName,
        lastName: lastName,
        timestamp: Timestamp.fromDate(new Date()),
    })
    const submissionId = docRef.id
    return submissionId
}

export async function eventGoAddToCartAndPay(
    eventId:string,
    uniqueId:string,
    firstName: string,
    lastName: string,
    email: string,
    eventTitle: string,
    photoURL: string,
    cart_items: any[]){
    if (auth.currentUser != null){
        const user_uid = auth.currentUser.uid
        //add to cart
        setDoc(doc(fs,"users",user_uid,"carts",uniqueId),{
            eventId: eventId, 
            uniqueId: uniqueId,
            timestamp: Timestamp.fromDate(new Date()),
            shoppingCart: cart_items
        }).then((e)=>{
            //pay
            let cartAllFree = true
            let payment_items: any[] = []
            cart_items.forEach((orderItm) => {
                if (orderItm.price > 0){
                    cartAllFree = false
                }
                let priceId = ""
                let quantity = 0
                if (orderItm.priceId != null){
                    priceId = orderItm.priceId
                }
                if (orderItm.quantity != null){
                    quantity = orderItm.quantity
                }
                if (priceId != "" && quantity > 0){
                    payment_items.push({
                        price: priceId,
                        quantity: quantity,
                    })
                }
            })
            if (cartAllFree){
                eventGoFreeItemsCheckout(
                    eventId,
                    '',
                    firstName,
                    lastName,
                    email,
                    uniqueId)
            }
            else{
                if (payment_items.length > 0){
                    eventGoPayShoppingCartPayment(
                        user_uid,
                        eventId,
                        eventTitle,
                        false,
                        firstName + " " + lastName,
                        firstName,
                        lastName,
                        cart_items,
                        payment_items,
                        email,
                        '',
                        'org',
                        false,
                        '',
                        '',
                        photoURL,
                        uniqueId
                    )
                }
                else{
                    alert('no payment items found')
                }
            }
            
        }).catch((e)=>{
            alert('something wrong with cart')
        })
    }
    else{
        signInAnonymously(auth).then((userRef) => {
            const user_uid = userRef.user.uid
            localStorage.setItem("user_uid",user_uid)
            userRef.user.getIdToken(true).then((idToken)=>{
                if (idToken != ""){
                  localStorage.setItem('user_token',idToken)
                }
            })
            let userDataa = {
                'firstName':firstName,
                'lastName':lastName,
                'email':email,
                'currentEvent': eventId,
                'lang': 'en',
                'userName': firstName + " " + lastName,
                'name': firstName + " " + lastName,
                'profileURL':'',
                'isAnonymous': true,
                'timestamp': Timestamp.fromDate(new Date()),
            }
            
            const userDataString = JSON.stringify({"user": userDataa})
            localStorage.setItem("user",userDataString)

            setDoc(doc(fs,'users',user_uid),userDataa).then((vv)=>{
                setDoc(doc(fs,"users",user_uid,"carts",uniqueId),{
                    eventId: eventId, 
                    uniqueId: uniqueId,
                    timestamp: Timestamp.fromDate(new Date()),
                    shoppingCart: cart_items
                }).then((e)=>{

                    //pay
            let cartAllFree = true
            let payment_items: any[] = []
            cart_items.forEach((orderItm) => {
                if (orderItm.price > 0){
                    cartAllFree = false
                }
                let priceId = ""
                let quantity = 0
                if (orderItm.priceId != null){
                    priceId = orderItm.priceId
                }
                if (orderItm.quantity != null){
                    quantity = orderItm.quantity
                }
                if (priceId != "" && quantity > 0){
                    payment_items.push({
                        price: priceId,
                        quantity: quantity,
                    })
                }
            })
            if (cartAllFree){
                eventGoFreeItemsCheckout(
                    eventId,
                    '',
                    firstName,
                    lastName,
                    email,
                    uniqueId)
            }
            else{
                if (payment_items.length > 0){
                    eventGoPayShoppingCartPayment(
                        user_uid,
                        eventId,
                        eventTitle,
                        false,
                        firstName + " " + lastName,
                        firstName,
                        lastName,
                        cart_items,
                        payment_items,
                        email,
                        '',
                        'org',
                        false,
                        '',
                        '',
                        photoURL,
                        uniqueId
                    )
                }
                else{
                    alert('no payment items found')
                }
            }

                    
                }).catch((e)=>{
                    alert('something wrong with cart')
                })
            }).catch((e) => {
                alert("something wrong with cart")
            })
        
        })
    }
}

export async function eventGoCheckout(
    firstName: string,
    lastName: string,
    email: string,
    eventId: string,
    eventName: string,
    uniqueId:string,
    orgId:string,
){
    if (auth.currentUser != null){
        //user already login
        const user_uid = auth.currentUser.uid
        fetch(`${API_ROOT_URL}v1EventsAPIIssueFreeTickets?user_uid=${user_uid}&event_id=${eventId}&unique_id=${uniqueId}&first_name=${firstName}&last_name=${lastName}&email=${email}`,{
            headers: {
              "Content-Type": "application/json",
            },}).then((response) => {

        })
    }
    else{
        //create guest account
    }
}

export async function eventGoPayShoppingCartPayment(
    uid:string,
    eventId:string, 
    eventName:string, 
    calculateTax:boolean,
    userName:string,
    userFirstName:string,
    userLastName:string,
    cart_items:any[],
    payment_items:any[],
    userEmail:string,
    dateSelected:string,
    orgId:string,
    applyCoupon:false,
    eventGoCoupon: string, 
    stripeCoupon: string,
    photoURL: string,
    uniqueId:string){

        const docRef = await addDoc(collection(fs, "service-stripe-customers", uid, "checkout_sessions"), 
        {   mode: "payment",
            automatic_tax: calculateTax,
            eventId: eventId,
            line_items: payment_items,
            allow_promotion_codes: applyCoupon,
            success_url: `${API_ROOT_URL}v1EventsAPIIssuePaidTickets?event_id=${eventId}&user_id=${uid}&unique_id=${uniqueId}`,
            cancel_url: window.location.origin + '/e/'+eventId,
            metadata: {
                eventId: eventId,
                eventTitle: eventName,
                orgId: orgId,
                eventName: eventName,
                firstName: userFirstName,
                lastName: userLastName,
                userUID: uid,
                url: `${API_ROOT_URL}v1EventsAPIIssuePaidTickets?event_id=${eventId}&user_id=${uid}&unique_id=${uniqueId}`,
                fromUA: window.navigator.userAgent.toLocaleLowerCase(),
                registerEmail: userEmail,
                registerName: userName,
                uniqueId: uniqueId,
                dateSelected: dateSelected,
                calculateTax: calculateTax,
                photoURL: photoURL,
                redirectURL: window.location.origin + '/order-confirmation/'+uniqueId
            },
        });

        onSnapshot(docRef, (docR) => {
            const docId = docR.id
            const docRData = docR.data() || {};
            const error = docRData.error;
            const url = docRData.url

            if (error) {
                // Show an error to your customer and
                // inspect your Cloud Function logs in the Firebase console.
                alert(`An error occured: could not purchase ticket`)
            }
            if (url) {
                window.location.assign(url)
            }
        })

        
}


function eventGoAPI(){

}

export default eventGoAPI