import React, { FC, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { auth, fs } from 'firebaseSetup'
import { signInWithEmailAndPassword } from "firebase/auth";
import { getDoc, getDocs, collection, doc } from "firebase/firestore"

export interface PageLoginProps {
  className?: string;
}

const loginSocials = [
  // {
  //   name: "Continue with Facebook",
  //   href: "#",
  //   icon: facebookSvg,
  // },
  // {
  //   name: "Continue with Twitter",
  //   href: "#",
  //   icon: twitterSvg,
  // },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];



const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const [userInfo, setUserInfo] = useState({
    email: '',
    password: '',
  })

  async function loginCred(email:string, password:string){
    console.log("Logging as " + email + " " + password)
    signInWithEmailAndPassword(auth,email,password).then((userVal) => {
      const userUID = userVal.user.uid
      localStorage.setItem("user_uid",userUID)
      let doccRef = doc(fs,"users",userUID)
      auth.currentUser?.getIdToken(true).then((idToken)=>{
        if (idToken != ""){
          localStorage.setItem('user_token',idToken)
        }
      })
      getDoc(doccRef).then((snapshot) => {
        if (snapshot.exists()){
          const userData = snapshot.data()
          const userDataString = JSON.stringify({"user": userData})
          localStorage.setItem("user",userDataString)

          if (userData.orgId != null && userData.orgId != ""){
            localStorage.setItem("org_id",userData.orgId)
            if (userData.orgName != null){
              localStorage.setItem("org_name",userData.orgName)
            }
            if (userData.profileURL != null){
              localStorage.setItem("user_profile",userData.profileURL)
            }
          }

          window.location.href = "/account"
        }
        else{
          window.location.href = "/account"
        }
      })
      
    }).catch((e)=>{
      alert(e)
    })
  }
  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Event Go | Login to your account</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6">
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                onChange={(e)=>{
                  const emailA = e.target.value
                  let userIn = userInfo
                  userIn.email = emailA
                  setUserInfo(userIn)
                }}
                className="mt-1"
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
                <Link to="/forgot-pass" className="text-sm">
                  Forgot password?
                </Link>
              </span>
              <Input type="password" onChange={(e)=>{
                const passwordA = e.target.value
                let userIn = userInfo
                userIn.password = passwordA
                setUserInfo(userIn)

              }} className="mt-1" />
            </label>
            
          </form>
          <ButtonPrimary onClick={()=>{
            loginCred(userInfo.email,userInfo.password)
          }}>Login</ButtonPrimary>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link to="/signup">Create an account</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
