import React, { FC, ReactNode, useEffect, useState } from "react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { EventDataType } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilter from "./HeaderFilter";
import EventCard from "components/EventCard/EventCard";
import StayCard from "components/StayCard/StayCard";

// OTHER DEMO WILL PASS PROPS


//
export interface SectionGridFeatureEventsProps {
  eventListings?: EventDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
}

const SectionGridFeaturePlaces: FC<SectionGridFeatureEventsProps> = ({
  eventListings = [],
  gridClass = "",
  heading = "Recommended Events For You",
  subHeading = "Popular events happened on Event Go across Canada",
  headingIsCenter = false,
  tabs = ['September','October','November','December'],
}) => {
  const renderCard = (evt: EventDataType) => {
    return <EventCard key={evt.id} data={evt} />;
  };

  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      <HeaderFilter
        tabActive={"Toronto"}
        subHeading={subHeading}
        tabs={tabs}
        heading={heading}
        onClickTab={() => {}}
      />
      <div
        className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
      >
        {eventListings.map((event) => renderCard(event))}
      </div>
      <div className="flex mt-16 justify-center items-center">
        <ButtonPrimary>Show me more</ButtonPrimary>
      </div>
    </div>
  );
};

export default SectionGridFeaturePlaces;
